<template>
  <div>
    <div class="main">
      <template v-if="featureList.length">
        <!-- <div
					v-if="!$store.getters.getCloseDisclamer"
					class="cookie-disclaimer"
					@click="closeDisclaimer"
				>
					<div style="font-size: 12px;text-align: center;">
						To help personalize content, tailor and measure ads and provide a
						safer experience, we use cookies. By clicking on or navigating the
						site, you agree to allow us to collect information on and off
						MachineryAccess through cookies. Learn more, including about
						available controls:
						<a
							:href="termsConfig.privacyPolicy"
							@click.stop
							target="_blank"
						>Privacy Policy</a>
					</div>
					<div style="margin-left:5px">X</div>
				</div> -->

        <!-- <div style="display: flex; justify-content: center; margin: 32px">
          <AdUnit :adInfo="freeStarAdConfig['ATF']" />
        </div> -->

        <div
          v-for="(machineUnits, index) in featureList"
          :key="machineUnits.title"
        >
          <!-- <template v-if="index === 1"> -->
          <!-- <template v-if="adIndex[index]"> -->

          <!-- <div style="display:flex;justify-content:center;margin:32px 0 7px">
							<AdUnit :adInfo="freeStarAdConfig[`inContent_${Math.ceil(index/adStep)}`]" />
						</div> -->

          <!-- <AdUnitHome
              :adSlotId="adsenseConfig[Math.floor(index / adStep)]"
              :adStyle="adStyle"
            /> -->

          <!-- <div style="text-align: center; margin: 32px 0 7px 10px">
              <img
                :src="partnerAdPicUrl"
                alt="Mazo Ad"
                @click="$router.push('/financing')"
              />
            </div>
          </template> -->

          <MachineBlock
            @record-homepage-position="storeHomePositionInStore"
            :blockInfo="machineUnits"
            :position="index"
            :categoryRoute="getCategoryRoute(machineUnits.title)"
          />

          <!-- </template> -->
        </div>

        <Spinkit v-if="!readyForScrollEvent" />

        <router-link
          v-for="(
            categoryShowLinkRoute, categoryShowLink
          ) in categoriesShowLink"
          :key="categoryShowLink"
          :to="`/categories/${categoryShowLinkRoute}?page=1`"
        >
          <h1 class="category-link h1-link" @click="storeHomePositionInStore">
            {{ categoryShowLink }} For Sale
          </h1>
        </router-link>
      </template>

      <Spinkit v-else />

      <Terms />

      <!-- <a
        class="conexpo"
        href="https://www.conexpoconagg.com/?referral=https://machineryaccess.com/"
        target="_blank"
        @click="recordReferral"
      >
        <img src="../pics/conexpo.png" />
			</a>-->
    </div>
  </div>
</template>

<script>
  import MachineBlock from '../components/MachineBlock'
  // import AdUnitHome from "../components/AdUnit_responsive";
  import getMachines from '../services/getMachines'
  import eventHelper from '../helpers/throttle_debounce_event'
  import { setStatusTag } from '../helpers/prerenderTags'
  import {
    add as addNoIndexMetaTag,
    remove as removeNoIndexMetaTag
  } from '../helpers/noIndexMetaTag'
  import { getHomePageTitleAndDescription } from '../helpers/getDocumentTitleAndDescription'
  // import sendLeads from '../services/sendLeads'
  import machineCategory from '../configs/machineCategory'
  // import termsConfig from '../configs/termsConfig'
  // import api from '../configs/apiConfig'
  // import { machineryHomePage } from "../configs/adsenseConfig";
  export default {
    data: () => {
      return {
        featureList: [],
        categoriesShowLink: {},
        readyForScrollEvent: true
        // adsenseConfig: machineryHomePage,
        // adStartCounter: 3,
        // adStyle: 'display: block; width: 1190px; margin: 32px 0 7px 10px',
        // adStep: 4,
        // adIndex: { 2: true, 6: true, 10: true, 14: true },
      }
    },
    components: {
      MachineBlock
      // AdUnitHome,
    },
    async created() {
      this.setupStaticValues()
      let startIndexForGetUnitsByCategory =
        this.$store.getters.getStartIndexForGetUnitsByCategory
      if (startIndexForGetUnitsByCategory < this.titles.length) {
        this.scrollEventForLazyGetUnits = eventHelper.debounce(
          this.handleScroll,
          100
        )
        window.addEventListener('scroll', this.scrollEventForLazyGetUnits)
      }
      if (!startIndexForGetUnitsByCategory) {
        let stopBeforeIndex = startIndexForGetUnitsByCategory + 6
        while (startIndexForGetUnitsByCategory < stopBeforeIndex) {
          try {
            let singleTitle = this.titles[startIndexForGetUnitsByCategory++]
            if (this.categoriesInStore[singleTitle]) {
              let units = (
                await getMachines.getPoolFeaturedMachines(singleTitle)
              ).data
              if (units.status) {
                //aws down solution
                // units = units.machines
                units = units.data
                if (units && units.length) {
                  this.featureList.push({
                    title: singleTitle,
                    units
                  })
                  this.$store.commit('setCachedUnits', {
                    key: `/${this.machineCategory[singleTitle]}`,
                    newUnits: {
                      machines: units
                    }
                  })
                }
              } else throw new Error(units.message)
            }
          } catch (error) {
            console.log('error in Home component: ', error)
          }
        }
        this.$store.commit(
          'setStartIndexForGetUnitsByCategory',
          stopBeforeIndex
        )
        this.$store.commit('setUnitsNumForEachPortion', this.featureList.length)
      } else {
        for (let i = 0; i < startIndexForGetUnitsByCategory; i++) {
          let singleTitle = this.titles[i]
          let cachedUnits = this.$store.getters.getCachedData(
            `/${this.machineCategory[singleTitle]}`
          )
          if (cachedUnits)
            this.featureList.push({
              title: singleTitle,
              units: cachedUnits.machines
            })
        }
      }
      let allCategories = this.categoriesInStore
      for (let singleCategory in allCategories) {
        if (!this.machineCategory[singleCategory])
          this.categoriesShowLink[singleCategory] =
            allCategories[singleCategory]
      }
      let temp = this.$store.getters.getPreviousPositions('/')
      if (temp) {
        this.$nextTick(() => window.scroll(0, temp))
        this.$store.commit('resetPreviousPositions', '/')
      }
      this.seoSetting()
      this.$gtag('config', this.$gtagId, {
        page_path: this.$route.fullPath
      })
      // this.$gtag('set', { user_id: this.$store.getters.getSessionId })
    },
    beforeUnmount() {
      removeNoIndexMetaTag()
      window.removeEventListener('scroll', this.scrollEventForLazyGetUnits)
    },
    methods: {
      seoSetting() {
        if (!this.featureList || this.featureList.length === 0) {
          addNoIndexMetaTag()
          setStatusTag(404)
        } else {
          removeNoIndexMetaTag()
          setStatusTag(200)
        }
        this.setCanonicalURL()
        this.setDocumentTitleAndDescription()
      },
      setCanonicalURL() {
        document
          .querySelector('link[rel="canonical"]')
          .setAttribute('href', 'https://machineryaccess.com')
      },
      setDocumentTitleAndDescription() {
        let { title, description } = getHomePageTitleAndDescription()
        document.title = title
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', description)
      },
      setupStaticValues() {
        // this.partnerAdPicUrl = `${api.API_S3}/partner-ads/mazoCapital-financing.jpg`
        // this.termsConfig = termsConfig
        this.categoriesInStore =
          this.$store.getters.getCachedData('categories') || machineCategory
        this.machineCategory = machineCategory
        this.titles = Object.keys(this.machineCategory)
      },
      getCategoryRoute(title) {
        return this.machineCategory[title]
      },
      storeHomePositionInStore() {
        let newPosition =
          window.pageYOffset || document.documentElement.scrollTop
        this.$store.commit('setPreviousPositions', {
          key: '/',
          newPosition
        })
      },
      closeDisclaimer() {
        this.$store.commit('setCloseDisclamer')
      },
      async handleScroll() {
        if (this.readyForScrollEvent) {
          this.readyForScrollEvent = false
          let startIndexForGetUnitsByCategory =
            this.$store.getters.getStartIndexForGetUnitsByCategory
          let UnitsNumForEachPortion =
            this.$store.getters.getUnitsNumForEachPortion
          let whenToGetUnits =
            UnitsNumForEachPortion.slice(
              0,
              startIndexForGetUnitsByCategory / 6 - 1
            ).reduce((pre, cur) => pre + cur, 0) *
              255 +
            100 +
            (UnitsNumForEachPortion[startIndexForGetUnitsByCategory / 6 - 1] /
              2) *
              255
          if (window.innerHeight + window.scrollY >= whenToGetUnits) {
            let stopBeforeIndex = startIndexForGetUnitsByCategory + 6
            if (stopBeforeIndex <= this.titles.length) {
              while (startIndexForGetUnitsByCategory < stopBeforeIndex) {
                try {
                  let singleTitle =
                    this.titles[startIndexForGetUnitsByCategory++]
                  if (this.categoriesInStore[singleTitle]) {
                    let units = (
                      await getMachines.getPoolFeaturedMachines(singleTitle)
                    ).data
                    if (units.status) {
                      //aws down solution
                      // units = units.machines
                      units = units.data
                      if (units && units.length) {
                        this.featureList.push({
                          title: singleTitle,
                          units
                        })
                        this.$store.commit('setCachedUnits', {
                          key: `/${this.machineCategory[singleTitle]}`,
                          newUnits: {
                            machines: units
                          }
                        })
                      }
                    } else throw new Error(units.message)
                  }
                } catch (error) {
                  console.log('error in Home component scroll-event : ', error)
                }
              }
              this.$store.commit(
                'setStartIndexForGetUnitsByCategory',
                stopBeforeIndex
              )
              this.$store.commit(
                'setUnitsNumForEachPortion',
                this.featureList.length
              )
            } else
              window.removeEventListener(
                'scroll',
                this.scrollEventForLazyGetUnits
              )
          }
          this.readyForScrollEvent = true
        }
      }
      // recordReferral() {
      //   sendLeads({
      //     leadType: 'referral',
      //     name: 'Conexpo',
      //     gtag: this.$gtag,
      //     from: 'home',
      //   })
      // },
    }
  }
</script>

<style>
  .cookie-disclaimer {
    padding: 10px 5px;
    background-color: rgba(70, 70, 70, 1);
    color: white;
    display: flex;
    border-top: 1px solid #fef4e4;
    align-items: center;
  }
  .cookie-disclaimer > div {
    line-height: 14px;
  }
  .cookie-disclaimer > div > a {
    color: white;
    text-decoration: underline;
    font-size: 12px;
  }
  .category-link {
    margin-top: 25px;
    padding-left: 10px;
  }
</style>
