import { createApp, defineAsyncComponent } from 'vue'
import App from './App.vue'
import router from './router'
import './generalSetUp.css'
import Spinkit from './components/Spinkit'
import store from './store'
import ImageDisplayer from './components/ImageDisplayer'
import Terms from './components/Terms'
import { gtagId } from './configs/miscConfig'

const app = createApp(App)
  .use(router)
  .use(store)
  .component('Terms', Terms)
  .component('Spinkit', Spinkit)
  .component(
    'NothingToShow',
    defineAsyncComponent(() => import('./components/NothingToShow'))
  )
  .component('ImageDisplayer', ImageDisplayer)
  .component(
    'BackButton',
    defineAsyncComponent(() => import('./components/BackButton'))
  )

app.config.globalProperties.$gtag = function () {
  if (process.env.NODE_ENV === 'production') {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(arguments)
  }
}
app.config.globalProperties.$gtagId = gtagId
app.mount(document.body)
