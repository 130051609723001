<template>
  <div class="terms-div">
    <div class="small-text-block">
      ©{{ new Date().getFullYear() }} Access Publishing, Inc.
    </div>
    <div class="small-text-block">
      <a :href="termsConfig.termOfServices" target="_blank">Terms of Use</a>
      and
      <a :href="termsConfig.privacyPolicy" target="_blank">Privacy Policy</a>.
    </div>
  </div>
</template>

<script>
import termsConfig from '../configs/termsConfig'
export default {
  created() {
    this.termsConfig = termsConfig
  }
}
</script>

<style>
.terms-div {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}
.small-text-block {
  color: #1f6075;
  font-size: 10px;
  text-align: center;
}
.small-text-block > a {
  font-size: 10px;
  text-decoration: underline;
  color: #1f6075;
}
</style>
