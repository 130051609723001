<template>
  <div class="nav-main-top">
    <div class="nav-content-top">
      <div>
        <router-link to="/">
          <img src="../pics/logo.svg" alt="Logo" />
        </router-link>
      </div>

      <div class="router-links">
        <router-link active-class="active" class="rounded router-link" to="/">
          Home
        </router-link>

        <router-link
          active-class="active"
          to="/auction"
          class="rounded router-link"
        >
          Auction
        </router-link>

        <!-- <router-link
          active-class="active"
          class="rounded router-link"
          to="/financing"
          rel="nofollow"
        >
          Financing
        </router-link> -->

        <!-- <router-link  active-class="active" to="/parts">Parts</router-link> -->

        <router-link
          active-class="active"
          to="/profile"
          rel="nofollow"
          class="rounded router-link"
          @click="recordPageView('/profile')"
        >
          Profile
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavbarTop',
    methods: {
      recordPageView(route) {
        this.$gtag('config', this.$gtagId, {
          page_path: route
        })
        // this.$gtag('set', { user_id: this.$store.getters.getSessionId })
      }
    }
  }
</script>

<style scoped>
  img {
    height: 40px;
  }
  .active {
    color: rgba(255, 160, 30, 1);
  }
</style>

<style>
  .nav-main-top {
    z-index: 100;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: rgba(45, 45, 45, 1);
    padding: 0 12px;
  }
  .nav-content-top {
    width: 96%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 56px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .nav-content-top img {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    /* width: 25%; */
  }
  .router-links {
    justify-self: right;
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 15px;
    justify-items: center;
  }
  .router-link {
    padding: 7px 9px;
    background-color: #4c4c4c;
  }
  /* .nav-content-top span {
    width: 25%;
    text-align: center;
  }
  .nav-content-top span > a {
    display: inline-block;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    width: 100px;
    background: #4c4c4c;
    text-align: center;
    padding: 7px;
  } */
  @media screen and (max-width: 500px) {
    .nav-content-top {
      grid-template-columns: 1fr;
    }
    .router-links {
      display: none;
    }
  }
</style>
