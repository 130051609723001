import api from './api'

const machineryRequest = api.apiMachinery()

export default {
  //field, then a specific filter is optional(categories, models, manufacturers )
  getFilters: (payload) => {
    return machineryRequest.post('/getDistinctFields', payload)
  },
}
