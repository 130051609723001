const state = {
  totastr: [],
}

const getters = {
  getTotastr: (state) => state.totastr,
}

const actions = {}
//message, duration
const mutations = {
  setTotastr(state, newTotastr) {
    if (newTotastr.duration)
      state.totastr.push({ id: state.totastr.length, ...newTotastr })
    else {
      newTotastr.duration = 1.8
      state.totastr.push({ id: state.totastr.length, ...newTotastr })
    }
  },
  removeTotastr(state, id) {
    state.totastr = state.totastr.filter(
      (singleTotastr) => singleTotastr.id !== id
    )
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
