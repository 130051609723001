<template>
  <div class="nav-main-bottom">
    <div class="nav-content-bottom center-item">
      <router-link
        exact
        active-class="active-home"
        class="nav-bottom-item home"
        to="/"
      >
        Home
      </router-link>

      <router-link
        exact
        active-class="active-auction"
        class="nav-bottom-item auction"
        to="/auction"
      >
        Auction
      </router-link>

      <!-- <router-link
        exact
        active-class="active-parts"
        class="nav-bottom-item parts"
        to="/parts"
        >Parts</router-link
      >-->

      <!-- <router-link
        exact
        active-class="active-financing"
        class="nav-bottom-item financing"
        rel="nofollow"
        to="/financing"
      >
        Financing
      </router-link> -->

      <router-link
        exact
        active-class="active-profile"
        class="nav-bottom-item profile"
        rel="nofollow"
        to="/profile"
        @click="recordPageView('/profile')"
      >
        Profile
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavbarBottom',
    methods: {
      recordPageView(route) {
        this.$gtag('config', this.$gtagId, {
          page_path: route
        })
        // this.$gtag('set', { user_id: this.$store.getters.getSessionId })
      }
    }
  }
</script>

<style scoped>
  .active-home {
    color: rgba(255, 160, 30, 1);
    background-image: url('../pics/home-active.svg');
  }
  .active-profile {
    color: rgba(255, 160, 30, 1);
    background-image: url('../pics/profile-active.svg');
  }
  .active-auction {
    color: rgba(255, 160, 30, 1);
    background-image: url('../pics/gavel-active.svg');
  }
  .active-parts {
    color: rgba(255, 160, 30, 1);
    background-image: url('../pics/parts-active.svg');
  }
  /* .active-financing {
    color: rgba(255, 160, 30, 1);
    background-image: url('../pics/request_quote_active.svg');
  } */
</style>

<style>
  .nav-main-bottom {
    min-width: 300px;
    z-index: 100;
    width: 100%;
    height: 50px;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
  }
  .nav-content-bottom {
    height: 50px;
    display: flex;
  }
  .nav-bottom-item {
    width: 33.3%;
    background-position: center 4px;
    background-repeat: no-repeat;
    padding: 28px 0 0;
    font-size: 14px;
    line-height: 20px;
    color: #97abb7;
    text-align: center;
  }
  .home {
    background-image: url('../pics/home.svg');
  }
  .profile {
    background-image: url('../pics/profile.svg');
  }
  .auction {
    background-image: url('../pics/gavel.svg');
  }
  .parts {
    background-image: url('../pics/parts.svg');
  }
  /* .financing {
    background-image: url('../pics/request_quote.svg');
  } */
  /* .info {
  background-image: url('../pics/info.svg');
  background-position: center 4px;
  background-repeat: no-repeat;
  padding: 28px 0 0;
} */
</style>
