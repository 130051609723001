export default (categoryArray) => {
	categoryArray.sort((a, b) => {
		if (a > b) return 1
		else if (a < b) return -1
		else return 0
	})
	let answer = {}
	for (let i = 0; i < categoryArray.length; i++) {
		answer[categoryArray[i]] = categoryArray[i]
			.toLowerCase()
			.replace(/\//g, ' ')
			.replace(/\s+/g, '-')
	}
	return answer
}
