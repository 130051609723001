// key rule:
// home page-- 1. /
// 2. `/${position}`
// auction-- '/auction'
// listingByCategory-- (temp) ex. '/aggregate-equipment'+filterRule

const state = {
  previousPositions: {},
}

const getters = {
  getPreviousPositions: (state) => (key) => state.previousPositions[key],
}

const actions = {}

const mutations = {
  setPreviousPositions(state, { key, newPosition }) {
    state.previousPositions = {
      ...state.previousPositions,
      [key]: newPosition,
    }
  },
  resetPreviousPositions(state, key) {
    state.previousPositions = {
      ...state.previousPositions,
      [key]: 0,
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
