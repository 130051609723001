import axios from 'axios'
import apiConfig from '../configs/apiConfig'
const headers = {
	site: 'machinery',
	'x-site-name': 'machineryaccess',
}
export default {
	apiMachinery: () =>
		axios.create({
			baseURL: apiConfig.API_MACHINERY,
			headers,
		}),
	apiS3: () =>
		axios.create({
			baseURL: apiConfig.API_S3,
			headers,
		}),
	apiNucleus: () =>
		axios.create({
			baseURL: apiConfig.API_NUCLEUS,
			headers,
		}),
	apiCache: () =>
		axios.create({
			baseURL: apiConfig.API_CACHE,
			headers,
		}),
}
