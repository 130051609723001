<template>
  <div style="position: relative; padding: 0 0 75%; width: 100%">
    <img
      style="
        position: absolute;
        top: 0;
        width: 100%;
        border-radius: 5px 5px 0 0;
        color: rgb(134, 132, 132);
      "
      :src="imgSrc"
      :alt="imgConfig.alt || 'placeholder'"
    />
    <p v-if="showTextHint" class="img-hint">Click For More Info</p>
  </div>
</template>

<script>
  import apiConfig from '../configs/apiConfig'
  import { placeHolderImg } from '../configs/miscConfig'
  import { isNonEmptyString } from '../helpers/stringUtil'
  export default {
    props: {
      //clientID, picName, unitID, alt
      imgConfig: {
        type: Object,
        default() {
          return {}
        }
      },
      showTextHint: { type: Boolean, default: false }
    },
    data: () => {
      return { imgSrc: null }
    },
    created() {
      let { clientID, picName, unitID } = this.imgConfig
      let canCreateImgUrl =
        isNonEmptyString(clientID) &&
        isNonEmptyString(picName) &&
        isNonEmptyString(unitID)
      this.imgSrc = `${apiConfig.API_S3}/${
        canCreateImgUrl
          ? `${clientID}/${unitID}/${
              this.showTextHint ? '320x240' : '640x480'
            }/${picName}`
          : placeHolderImg
      }`
      // this.imgSrc = `${apiConfig.API_S3}/${
      //   canCreateImgUrl
      //     ? `${clientID}${this.showTextHint ? '' : '/raw'}/${picName}`
      //     : placeHolderImg
      // }`
    }
  }
</script>

<style>
  .img-hint {
    font-size: 13px;
    color: white;
    position: absolute;
    left: 0;
    padding: 2px;
    background: linear-gradient(rgba(31, 33, 35, 0.1), #1f2123);
    line-height: 16px;
    border-radius: 5px 0 0 0;
  }
</style>
