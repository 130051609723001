import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home'
// import AdUnit from './components/AdUnit'
// import Profile from './views/Profile'
// import ListByCategory from './views/ListByCategory'
// import IndividualMachine from './views/IndividualMachine'
import getFilters from './services/getFilters'
import { state as cachedDataStore } from './store/modules/cachedData'
import { state as userInfo } from './store/modules/userInfo'
import transformCategories from './helpers/transformCategories'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Home
    },
    // {
    //   path: '/test',
    //   component: AdUnit
    // },
    {
      path: '/auction',
      component: () => import('./views/Auction')
    },
    // {
    //   path: '/parts',
    //   component: () => import('./views/Parts'),
    // },
    {
      path: '/profile',
      component: () => import('./views/Profile')
    },
    // {
    //   path: '/financing',
    //   component: () => import('./views/Financing')
    // },
    {
      path: '/categories/:category',
      component: () => import('./views/ListByCategory'),
      beforeEnter: (to, from, next) => {
        if (
          Object.values(cachedDataStore.cachedData.categories).includes(
            to.params.category
          )
        ) {
          const getRightPath = () => {
            to.query.page = '1'
            let rightPath = `${to.path}?`
            let query = ''
            let queryEntries = Object.entries(to.query)
            for (let index in queryEntries) {
              if (Array.isArray(queryEntries[index][1])) {
                for (let innerIndex in queryEntries[index][1])
                  if (innerIndex === queryEntries[index][1].length - 1)
                    query += `${queryEntries[index][0]}=${queryEntries[index][1][innerIndex]}`
                  else
                    query += `${queryEntries[index][0]}=${queryEntries[index][1][innerIndex]}&`
              } else {
                if (index === queryEntries.length - 1) {
                  query += `${queryEntries[index][0]}=${queryEntries[index][1]}`
                } else {
                  query += `${queryEntries[index][0]}=${queryEntries[index][1]}&`
                }
              }
            }
            rightPath += query
            return rightPath
          }
          let toPage = to.query.page
          if (toPage) {
            if (isNaN(parseInt(toPage))) {
              next(getRightPath())
            } else next()
          } else {
            next(getRightPath())
          }
        } else next('/')
      }
    },
    {
      path: '/categories/:category/units-for-sale/:title/:id',
      beforeEnter: (to, from, next) => {
        if (
          Object.values(cachedDataStore.cachedData.categories).includes(
            to.params.category
          )
        )
          next()
        else next('/')
      },
      component: () => import('./views/IndividualMachine')
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('./components/NothingToShow')
    }
  ]
})
router.beforeEach(async (to, from, next) => {
  if (!cachedDataStore.cachedData.categories) {
    try {
      let allCategory = (await getFilters.getFilters({ field: 'category' }))
        .data
      if (allCategory.status) {
        allCategory = allCategory.list
        let categories = transformCategories(allCategory)
        cachedDataStore.cachedData = {
          ...cachedDataStore.cachedData,
          categories
        }
        next()
      } else throw new Error(allCategory.message)
    } catch (error) {
      console.log('error in getting category from back end:', error)
      next('/')
    }
  } else if (from.path === '/profile' && userInfo.redirectRoute) {
    userInfo.redirectRoute = null
    userInfo.redirectFromProfile = false
    userInfo.pendingUnitForEmail = null
    next()
  } else {
    let { m } = from.query
    if (m && to.query.m !== m) {
      next({
        ...to,
        query: {
          ...to.query,
          m
        }
      })
    } else {
      next()
    }
  }
})
export default router
