export default {
  throttling: (cb, delay) => {
    let previous
    return function(args) {
      let currentCall = Date.now()
      if (!previous || currentCall - previous >= delay) {
        previous = currentCall
        cb(args)
      }
    }
  },
  debounce: (cb, delay) => {
    let lastCall
    return function(args) {
      if (lastCall) clearTimeout(lastCall)
      lastCall = setTimeout(() => cb(args), delay)
    }
  }
}
