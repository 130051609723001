// key rule:
// home page-- ex. '/aggregate-equipment'
// auction-- '/auction'
// listingByCategory-- ex. '/aggregate-equipment'+filterRule

//filter key name rule:
//category: 'categories'
//manufacturer: ex. 'aggregate-equipment-manufacturers'
//model: ex. 'aggregate-equipment-${manufacturer}-models'

const state = {
  cachedData: {
    states: {
      Western: [
        'CA',
        'OR',
        'WA',
        'ID',
        'MT',
        'NV',
        'UT',
        'AZ',
        'NM',
        'CO',
        'WY',
        'AK',
        'HI',
      ],
      Central: [
        'ND',
        'SD',
        'NE',
        'KS',
        'OK',
        'TX',
        'MN',
        'IA',
        'MO',
        'AR',
        'LA',
        'WI',
        'IL',
        'MI',
        'IN',
        'OH',
        'KY',
        'TN',
        'MS',
        'AL',
      ],
      Eastern: [
        'ME',
        'VT',
        'NH',
        'MA',
        'CT',
        'NY',
        'NJ',
        'DE',
        'MD',
        'DC',
        'PA',
        'WV',
        'VA',
        'NC',
        'SC',
        'GA',
        'FL',
      ],
      All: [],
    },
    sortPrice: { 'low to high': '0', 'high to low': '1' },
  },
}

const getters = {
  getCachedData: (state) => (key) => state.cachedData[key],
  getIndividualCachedUnit: (state) => ({ key, _id }) => {
    if (state.cachedData[key])
      return state.cachedData[key].machines.find(
        (machine) => machine._id === _id
      )
    return null
  },
}

const actions = {}

const mutations = {
  setCachedUnits(state, { key, newUnits }) {
    state.cachedData = {
      ...state.cachedData,
      [key]: newUnits,
    }
  },
}

export {
  state,
  getters,
  actions,
  mutations,
}
