<template>
  <div style="position: relative">
    <div class="block-header">
      <router-link :to="`/categories/${categoryRoute}?page=1`">
        <h1 class="h1-link" @click="viewCategoryList(`h1_${categoryRoute}`)">
          {{ blockInfo.title }} For Sale
        </h1>
      </router-link>

      <transition name="fade">
        <!-- v-show="showButtons" -->

        <router-link :to="`/categories/${categoryRoute}?page=1`">
          <button class="see-all" @click="viewCategoryList('see_all')">
            See All
          </button>
        </router-link>
      </transition>
    </div>

    <div ref="targetBlock" class="block-body">
      <transition name="fade">
        <!-- v-show="scrollLeft > 0" -->

        <button
          v-show="scrollLeft > 0"
          @click="adjustScrollWidthOnClick(-1)"
          class="arrow-button arrow-back-button dark-shadow"
        >
          <img
            class="arrow-next"
            src="../pics/arrow-back.svg"
            alt="previous feature unit"
          />
        </button>
      </transition>

      <div ref="cardGroup" class="machine-card-group">
        <!-- <template v-if="blockInfo.units.length"> -->

        <MachineCard
          @record-block-position="saveBlockPositionInStore"
          v-for="machineUnit in blockInfo.units"
          :key="machineUnit._id"
          :unit="machineUnit"
          :category="categoryRoute"
        />

        <router-link
          v-if="scrollRight >= 0"
          :to="`/categories/${categoryRoute}?page=1`"
        >
          <MachineCard
            @view-more-category-list="viewCategoryList('see_more')"
            :category="categoryRoute"
          />
        </router-link>

        <!-- </template> -->

        <!-- <NothingToShow v-else :msg="noUnitMSG" :needButton="false" /> -->
      </div>

      <transition name="fade">
        <button
          v-show="showButtons"
          class="arrow-button dark-shadow"
          @click="adjustScrollWidthOnClick(1)"
        >
          <img
            class="arrow-next"
            src="../pics/arrow-next.svg"
            alt="next feature unit"
          />
        </button>
      </transition>
    </div>
  </div>
</template>

<script>
  import eventHelper from '../helpers/throttle_debounce_event'
  import recordBtnClickGTag from '../services/recordBtnClickGTag'
  import MachineCard from './MachineCard'
  export default {
    data: () => {
      return {
        // noUnitMSG:
        //   'No available units for this category, please check back later!',
        // noUnitMSG: 'Please click on see all to view all units for this category!',
        // adjustScrollWidthOnClick: null,
        // viewportWidth:
        //   document.body.clientWidth > 1200 ? 1200 : document.body.clientWidth,
        scrollRight: 0,
        scrollLeft: 0,
        currentCardWidth: 0
      }
    },
    emits: ['record-homepage-position'],
    props: {
      blockInfo: Object,
      position: Number,
      categoryRoute: String
    },
    components: {
      MachineCard
    },
    computed: {
      showButtons: function () {
        if (this.scrollRight >= this.currentCardWidth) return true
        else return this.currentCardWidth - this.scrollRight <= 93
      }
    },
    mounted() {
      this.offset = null
      this.viewportWidth =
        document.body.clientWidth > 1200 ? 1200 : document.body.clientWidth
      this.adjustScrollWidthOnClick = eventHelper.throttling(
        this.initialAdjustScrollWidthOnClick,
        200
      )
      this.$refs.targetBlock.addEventListener(
        'scroll',
        eventHelper.debounce(this.initialAdjustScrollWidthOnScroll, 250)
      )
      this.resizeEvent = eventHelper.debounce(
        this.initialAdjustViewportWidthOnResize,
        250
      )
      window.addEventListener('resize', this.resizeEvent)
      this.currentCardWidth = this.viewportWidth > 500 ? 220 : 185

      this.lastScroll =
        this.currentCardWidth - (this.viewportWidth % this.currentCardWidth)
      if (this.$store.getters.getPreviousPositions(`/${this.position}`)) {
        this.$refs.targetBlock.scrollLeft =
          this.$store.getters.getPreviousPositions(`/${this.position}`)
        this.scrollLeft = this.$refs.targetBlock.scrollLeft
        this.scrollRight =
          this.$refs.cardGroup.clientWidth -
          this.$refs.targetBlock.clientWidth -
          this.$refs.targetBlock.scrollLeft
        this.$store.commit('resetPreviousPositions', `/${this.position}`)
      } else {
        this.scrollRight =
          this.$refs.cardGroup.clientWidth - this.$refs.targetBlock.clientWidth
      }
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.resizeEvent)
    },
    methods: {
      initialAdjustViewportWidthOnResize() {
        this.viewportWidth =
          document.body.clientWidth > 1200 ? 1200 : document.body.clientWidth
        this.currentCardWidth = this.viewportWidth > 500 ? 220 : 185
        this.lastScroll =
          this.currentCardWidth - (this.viewportWidth % this.currentCardWidth)
      },
      initialAdjustScrollWidthOnClick(direction) {
        let classToBeAdded
        if (direction > 0) {
          classToBeAdded = 'scroll-right'
          this.recordButtonClick('arrow_next')
        } else {
          classToBeAdded = 'scroll-left'
          this.recordButtonClick('arrow_back')
        }
        if (this.scrollRight >= this.currentCardWidth) {
          this.$refs.targetBlock.scrollLeft += this.currentCardWidth * direction
          document.documentElement.style.setProperty(
            '--translate-value-in-animation',
            `${this.currentCardWidth}px`
          )
        } else if (this.lastScroll >= this.currentCardWidth - 93) {
          //first time to this block
          if (this.offset === null) {
            document.documentElement.style.setProperty(
              '--translate-value-in-animation',
              `${this.lastScroll}px`
            )
            this.$refs.targetBlock.scrollLeft += this.lastScroll * direction
            this.offset = 1
            //done for this block
          } else if (!this.offset) {
            this.offset = null
            document.documentElement.style.setProperty(
              '--translate-value-in-animation',
              `${this.currentCardWidth}px`
            )
            this.$refs.targetBlock.scrollLeft +=
              this.currentCardWidth * direction
          } else {
            document.documentElement.style.setProperty(
              '--translate-value-in-animation',
              `${this.lastScroll}px`
            )
            this.$refs.targetBlock.scrollLeft += this.lastScroll * direction
            this.offset--
          }
        } else {
          document.documentElement.style.setProperty(
            '--translate-value-in-animation',
            `${this.currentCardWidth}px`
          )
          this.$refs.targetBlock.scrollLeft += this.currentCardWidth * direction
        }
        this.$refs.cardGroup.classList.add(classToBeAdded)
        setTimeout(() => {
          this.$refs.cardGroup.classList.remove(classToBeAdded)
        }, 250)
      },
      initialAdjustScrollWidthOnScroll() {
        this.scrollLeft = this.$refs.targetBlock.scrollLeft
        this.scrollRight =
          this.$refs.cardGroup.clientWidth -
          this.$refs.targetBlock.clientWidth -
          this.$refs.targetBlock.scrollLeft
      },
      saveBlockPositionInStore() {
        this.$store.commit('setPreviousPositions', {
          key: `/${this.position}`,
          newPosition: this.$refs.targetBlock.scrollLeft
        })
        this.$emit('record-homepage-position')
      },
      recordButtonClick(btnString) {
        recordBtnClickGTag({ prefix: 'home_', btnString, gtag: this.$gtag })
      },
      viewCategoryList(btnString) {
        this.recordButtonClick(btnString)
        this.saveBlockPositionInStore()
      }
    }
  }
</script>

<style>
  .block-header {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
  }
  .block-body {
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-padding: 10px;
  }
  .machine-card-group {
    position: relative;
    display: flex;
  }
  .arrow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(255, 160, 30, 1);
    display: flex;
    justify-content: center;
  }
  .arrow-back-button {
    z-index: 1;
    left: 5px;
  }
  .arrow-next {
    align-self: center;
  }
  .see-all {
    margin-right: 8px;
    float: right;
    color: rgba(70, 70, 70, 1);
    text-decoration: underline;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter-to,
  .fade-leave-from {
    opacity: 1;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .scroll-right {
    animation: scroll-right 0.25s ease-in-out;
    -webkit-animation: scroll-right 0.25s ease-in-out;
    -moz-animation: scroll-right 0.25s ease-in-out;
  }
  .scroll-left {
    animation: scroll-left 0.25s ease-in-out;
    -webkit-animation: scroll-left 0.25s ease-in-out;
    -moz-animation: scroll-left 0.25s ease-in-out;
  }
  @keyframes scroll-right {
    0% {
      transform: translateX(var(--translate-value-in-animation));
    }
    100% {
      transform: translateX(0);
    }
  }
  @-moz-keyframes scroll-right {
    0% {
      -moz-transform: translateX(var(--translate-value-in-animation));
    }
    100% {
      -moz-transform: translateX(0);
    }
  }
  @-webkit-keyframes scroll-right {
    0% {
      -webkit-transform: translateX(var(--translate-value-in-animation));
    }
    100% {
      -webkit-transform: translateX(0);
    }
  }
  @keyframes scroll-left {
    0% {
      transform: translateX(calc(-1 * var(--translate-value-in-animation)));
    }
    100% {
      transform: translateX(0);
    }
  }
  @-webkit-keyframes scroll-left {
    0% {
      -webkit-transform: translateX(
        calc(-1 * var(--translate-value-in-animation))
      );
    }
    100% {
      -webkit-transform: translateX(0);
    }
  }
  @-moz-keyframes scroll-left {
    0% {
      -moz-transform: translateX(
        calc(-1 * var(--translate-value-in-animation))
      );
    }
    100% {
      -moz-transform: translateX(0);
    }
  }
  @media (max-width: 450px) {
    .arrow-button {
      width: 45px;
      height: 45px;
    }
  }
</style>
