const get = () => document.querySelector('meta[name="robots"]'),
  add = () => {
    let exsitingTag = get()
    if (!exsitingTag) {
      const metaRobots = document.createElement('meta')
      metaRobots.name = 'robots'
      metaRobots.content = 'noindex'
      document.head.appendChild(metaRobots)
    }
  },
  remove = () => {
    let ele = get()
    if (ele) ele.remove()
  }
export { add, remove }
