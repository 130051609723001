export default {
  'Aggregate Equipment': 'aggregate-equipment',
  'Asphalt / Concrete Equipment': 'asphalt-concrete-equipment',
  Attachments: 'attachments',
  Backhoes: 'backhoes',
  'Bucket Trucks': 'bucket-trucks',
  Compactors: 'compactors',
  Cranes: 'cranes',
  'Crawler Loaders': 'crawler-loaders',
  'Crawler Tractors': 'crawler-tractors',
  Drills: 'drills',
  'Dump Trucks': 'dump-trucks',
  Excavators: 'excavators',
  Forklifts: 'forklifts',
  Generators: 'generators',
  Lifts: 'lifts',
  'Logging Equipment': 'logging-equipment',
  'Motor Graders': 'motor-graders',
  'Motor Scrapers': 'motor-scrapers',
  'Offhighway Trucks': 'offhighway-trucks',
  Other: 'other',
  'Skid Steers': 'skid-steers',
  'Trenching Equipment': 'trenching-equipment',
  'Water Equipment': 'water-equipment',
  'Wheel Loaders': 'wheel-loaders'
}
