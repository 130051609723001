const getHomePageTitleAndDescription = () => {
    let title = 'Used Construction Equipment, Parts For Sale and At Auction'
    return {
      title: `${title} | MachineryAccess`,
      description: `${title} such as Aggregate, Tractors, Excavators, Graders and Many More! | MachineryAccess`
    }
  },
  getListByCategoryPageTitleAndDescription = (machineryAttributes) => {
    let { models, manufacturers, states, category } = machineryAttributes
    let title = `Used${manufacturers ? ' ' + manufacturers : ''}${
      models ? ' ' + models : ''
    } ${category} For Sale${
      states ? ` in ${states ? states + ' ' : ''}US` : ''
    }`
    return {
      title,
      description: `Find ${title} at MachineryAccess.com. Your Trusted Source for ${title} since 1992`
    }
  },
  getIndividualPageTitleAndDescription = (machineryAttributes) => {
    let { year, model, manufacturer, _id, category } = machineryAttributes,
      title = `${year} ${manufacturer}${model ? ' ' + model : ''}`
    return {
      title: `Used ${title} | MachineryAccess - ${_id}`,
      description: `Find Used ${title} ${category} For Sale at MachineryAccess.com`
    }
  }

export {
  getHomePageTitleAndDescription,
  getListByCategoryPageTitleAndDescription,
  getIndividualPageTitleAndDescription
}
