import api from './api'

const machineryRequest = api.apiMachinery()
const cacheRequest = api.apiCache()
export default {
  // filterName(categories, models, manufacturers)
  //category

  getPoolFeaturedMachines: (category) => {
    //aws down solution
    // return machineryRequest.post('/getMachines', {
    //   categories: category,
    //   range: 8
    // })
    return cacheRequest.post('/fetchPoolMachinery', { category })
  },
  //categories, range, page, other filters
  getMachines: (payload) => {
    return machineryRequest.post('/getMachines', payload)
  }
}
