const state = {
  profile: {
    firstname: null,
    lastname: null,
    email: null,
    country: null,
    phone: null,
  },
  redirectRoute: null,
  emailedUnits: {},
  viewedUnits: {},
  closeDisclamer: false,
  startIndexForGetUnitsByCategory: 0,
  unitsNumForEachPortion: [0, 0, 0, 0],
  redirectFromProfile: false,
  pendingUnitForEmail: null
}

const getters = {
  getProfile: (state) => state.profile,
  getSessionId: (state) => state.profile.userSessionId,
  getRedirectRoute: (state) => state.redirectRoute,
  getRedirectFromProfile: (state) => state.redirectFromProfile,
  getPendingUnitForEmail: (state) => state.pendingUnitForEmail,
  getEmailedUnits: (state) => state.emailedUnits,
  getViewedUnits: (state) => state.viewedUnits,
  getCloseDisclamer: (state) => state.closeDisclamer,
  getStartIndexForGetUnitsByCategory: (state) =>
    state.startIndexForGetUnitsByCategory,
  getUnitsNumForEachPortion: (state) => state.unitsNumForEachPortion,
}

const actions = {}

const mutations = {
  setRedirectRoute(state, newRoute) {
    state.redirectRoute = newRoute
  },
  setRedirectFromProfile(state, newValue) {
    state.redirectFromProfile = newValue
  },
  setPendingUnitForEmail(state, newValue) {
    state.pendingUnitForEmail = newValue
  },
  setProfile(state, newProfile) {
    state.profile = {
      ...state.profile,
      ...newProfile,
    }
  },
  setEmailedUnits(state, newUnit) {
    state.emailedUnits = {
      ...state.emailedUnits,
      ...newUnit,
    }
  },
  setViewedUnits(state, newUnit) {
    state.viewedUnits = {
      ...state.viewedUnits,
      ...newUnit,
    }
  },
  setCloseDisclamer(state) {
    state.closeDisclamer = true
  },
  deleteProfile(state) {
    state.profile = {
      firstname: null,
      lastname: null,
      email: null,
      country: null,
      phone: null,
    }
  },
  resetRedirectRoute(state) {
    state.redirectRoute = null
  },
  setStartIndexForGetUnitsByCategory(state, newValue) {
    state.startIndexForGetUnitsByCategory = newValue
  },
  setUnitsNumForEachPortion(state, newValue) {
    if (state.startIndexForGetUnitsByCategory) {
      let endIndex = state.startIndexForGetUnitsByCategory / 6 - 1
      for (let i = 0; i < endIndex; i++)
        newValue -= state.unitsNumForEachPortion[i]
    }
    state.unitsNumForEachPortion[
      state.startIndexForGetUnitsByCategory / 6 - 1
    ] = newValue
  },
}

export {
  state,
  getters,
  actions,
  mutations,
}
