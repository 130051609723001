<template>
  <router-link
    v-if="unit"
    :to="`/categories/${category}/units-for-sale/${title}/${unit._id}`"
  >
    <div class="card-container rounded" @click="$emit('record-block-position')">
      <imageDisplayer
        style="padding: 0 0 85%"
        :imgConfig="{
          clientID: unit.clientid,
          picName: unit.pics[0],
          unitID: unit._id,
          alt: imgAlt
        }"
      />

      <span style="color: rgba(70, 70, 70, 1)">
        {{
          `${this.unit.year || ''} ${this.unit.manufacturer} ${
            this.unit.model || ''
          }`
        }}
      </span>

      <span style="color: #1f7fdf">
        {{
          `${this.unit.city || ''}, ${this.unit.state} (${this.unit.zipcode})`
        }}
      </span>
    </div>
  </router-link>

  <div v-else class="card-see-all rounded">
    <button
      style="color: rgba(70, 70, 70, 1); margin-left: 10px"
      @click="$emit('view-more-category-list')"
    >
      See More
    </button>
  </div>
</template>

<script>
  export default {
    props: {
      unit: Object,
      category: String
    },
    emits: ['record-block-position', 'view-more-category-list'],
    created() {
      if (this.unit) {
        this.title = `${this.unit.manufacturer}-${
          this.unit.model ? this.unit.model + '-' : ''
        }${
          this.unit.state + (this.unit.year ? '-' + this.unit.year : '')
        }`.replace(/[^a-zA-Z0-9]/g, '-')
        this.imgAlt = `${this.unit.year ? this.unit.year + ' ' : ''}${
          this.unit.manufacturer
        } ${this.unit.category} For Sale In ${this.unit.state}`
      }
    }
  }
</script>

<style>
  .card-container {
    flex: 0 0 auto;
    width: 210px;
    height: 240px;
    overflow: hidden;
    background-color: white;
    margin: 15px 0 0 10px;
    scroll-snap-align: start;
  }
  .card-container:hover {
    cursor: pointer;
  }
  .card-container span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    font-size: 14px;
    padding: 8px 8px 2px;
    background: #fff;
  }
  .card-see-all {
    flex: 0 0 auto;
    display: flex;
    width: 210px;
    height: 240px;
    margin: 15px 0 0 10px;
  }
  .card-see-all a {
    padding-left: 20px;
    align-self: center;
    color: rgb(31, 127, 223);
  }
</style>
