import { createStore } from 'vuex'
import * as userInfo from './modules/userInfo'
import totastr from './modules/totastr'
import previousPositions from './modules/previousPositions'
import * as cachedData from './modules/cachedData'

export default createStore({
  modules: {
    userInfo,
    previousPositions,
    cachedData,
    totastr
  }
})
