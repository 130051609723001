<template>
  <div>
    <NavbarTop />

    <div class="totastr-block" v-if="$store.getters.getTotastr.length">
      <div
        class="totastr orange-button rounded default-button"
        v-for="singleTotastr in $store.getters.getTotastr"
        :style="{ animationDelay: `${singleTotastr.duration}s` }"
        @click="clearTotastr(singleTotastr.id)"
        @animationend="clearTotastr(singleTotastr.id)"
        :key="singleTotastr.id"
      >
        {{ singleTotastr.message }}
      </div>
    </div>

    <router-view></router-view>

    <NavbarBottom />
  </div>
</template>

<script>
  import NavbarBottom from './components/NavbarBottom'
  import NavbarTop from './components/NavbarTop'
  import generateSessionId from './helpers/generateSessionId'

  export default {
    name: 'app',
    components: {
      NavbarBottom,
      NavbarTop
    },
    created() {
      let userId = window.localStorage.getItem('userSessionId')
      if (!userId) {
        userId = generateSessionId()
        window.localStorage.setItem('userSessionId', userId)
      }
      //NOTE: all gtag related property names use underscore by google convention
      //      ex. home_see_all
      this.$gtag('js', new Date())
      this.$gtag('set', { user_id: userId })
      let userInfo = JSON.parse(window.localStorage.getItem('profile'))
      this.$store.commit('setProfile', {
        ...userInfo,
        userSessionId: userId
      })
    },
    methods: {
      clearTotastr(id) {
        this.$store.commit('removeTotastr', id)
      }
    }
  }
</script>

<style>
  .totastr {
    animation: toastrDisappear 0.5s ease-out;
    text-align: center;
  }
  .totastr-block {
    width: 300px;
    max-width: 85%;
    opacity: 1;
    position: fixed;
    z-index: 5;
    left: 50%;
    top: 115px;
    transform: translateX(-50%);
  }
  @keyframes toastrDisappear {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
</style>
